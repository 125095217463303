import { Device } from 'src/types/IDevices';

const getDeviceType = (item: Device) => {
  switch (item?.__typename) {
    case 'PrinterDevice':
      return 'printer';
    case 'PCDevice':
      return 'pc';
    default:
      return '';
  }
};

export default getDeviceType;
