import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/interface/v1/analytics/types';
import {
  URLS,
  getHPSmartManageDeviceUsersAndSharingUrl,
  getHPSmartPrinterOverviewUrl
} from './urls';

const activity = 'UnifiedAcctMgmt-v01';
const screenPath = '/ReactMyAccountDevices/';
const eventDetailVersion = '1.5.0';
export const enum ScreenName {
  DEVICES = 'Devices',
  DEVICE_DETAILS = 'DeviceDetails'
}

export type AnalyticsEventPropTypes = {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlName?: string;
  controlDetail?: string;
  version: string;
};

export const publishEvent = (
  event: AnalyticsEventPropTypes,
  options?: PublishCdmEventsOptions
) => {
  const publishCdmEvents = (window as any)?.Shell?.v1?.analytics
    ?.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  };

  publishCdmEvents([cdmEvents], options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_TILE_CLICKED: 'ControlTileClicked',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  CONTROL_HYPER_LINK_CLICKED: 'ControlHyperLinkClicked',
  MODULE_DISPLAYED: 'ModuleDisplayed'
};

export const DevicesScreenDisplayed = (totalDeviceCT: string) => {
  return {
    action: ACTIONS.SCREEN_DISPLAYED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICES,
    actionAuxParams: totalDeviceCT,
    version: eventDetailVersion
  };
};

export const RefreshDevicesButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: ScreenName.DEVICES,
  controlName: 'RefreshDevices',
  version: eventDetailVersion
};

export const ControlTileClicked = (deviceDetails: string) => {
  return {
    action: ACTIONS.CONTROL_TILE_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICES,
    controlName: 'DeviceDetails',
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const SeeDevicesPurchasedButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: ScreenName.DEVICES,
  controlName: 'SeeDevicesPurchased',
  controlDetail: URLS.DevicesPurchased.slice(0, 256),
  version: eventDetailVersion
};

export const GetSupportForDeviceNotShownButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: ScreenName.DEVICES,
  controlName: 'GetSupportForDeviceNotShown',
  controlDetail: URLS.DeviceSupportNotShown,
  version: eventDetailVersion
};

export const HPSmartDevicesMyPrintersButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: ScreenName.DEVICES,
  controlName: 'HPSmartDevicesMyPrinters',
  controlDetail: getHPSmartPrinterOverviewUrl(),
  version: eventDetailVersion
};

export const DeviceRegisterButtonClicked = {
  action: ACTIONS.CONTROL_BUTTON_CLICKED,
  activity,
  screenPath,
  screenName: ScreenName.DEVICES,
  controlName: 'DeviceRegister',
  controlDetail: URLS.Register,
  version: eventDetailVersion
};

export const DevicesError = (errorInfo: string) => {
  return {
    actionAuxParams: `error=${errorInfo.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${screenPath}Devices/`,
    screenName: 'DevicesError',
    version: eventDetailVersion
  };
};

// DEVICE DETAILS PAGE

export const DeviceDetailsError = (errorInfo: string) => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: `${screenPath}DeviceDetails/`,
    screenName: 'DeviceDetailsError',
    version: eventDetailVersion
  };
};

export const DeviceDetailsScreenDisplayed = (deviceDetails: string) => {
  return {
    action: ACTIONS.SCREEN_DISPLAYED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const PrinterOverview = (deviceDetails: string) => {
  return {
    action: ACTIONS.CONTROL_HYPER_LINK_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'PrinterOverview',
    controlDetail: getHPSmartPrinterOverviewUrl(),
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const DeviceOverview = (deviceDetails: string) => {
  return {
    action: ACTIONS.CONTROL_HYPER_LINK_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'PC',
    controlName: 'DeviceOverview',
    controlDetail: URLS.SupportDashboard,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const ViewWarrantyStatus = (deviceDetails: string) => {
  return {
    action: ACTIONS.CONTROL_HYPER_LINK_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'ViewWarrantyStatus',
    controlDetail: URLS.SupportDashboard,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const ManageDeviceInstantInkSubscription = (deviceDetails: string) => {
  return {
    action: ACTIONS.CONTROL_HYPER_LINK_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'ManageDeviceInstantInkSubscription',
    controlDetail: URLS.ManageInstantInk,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const ManageDeviceHpCareSubscription = (deviceDetails: string) => {
  return {
    action: ACTIONS.CONTROL_HYPER_LINK_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'ManageDeviceHpCareSubscription',
    controlDetail: URLS.HPCarePack,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const ManageDeviceUsersAndSharing = (deviceDetails: string) => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'ManageDeviceUsersAndSharing',
    controlDetail: getHPSmartManageDeviceUsersAndSharingUrl(),
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};

export const GetDeviceSupport = (deviceDetails: string) => {
  return {
    action: ACTIONS.CONTROL_BUTTON_CLICKED,
    activity,
    screenPath,
    screenName: ScreenName.DEVICE_DETAILS,
    screenMode: 'Printer',
    controlName: 'GetDeviceSupport',
    controlDetail: URLS.DeviceSupport,
    actionAuxParams: deviceDetails,
    version: eventDetailVersion
  };
};
