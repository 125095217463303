import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 40px;
  padding-right: 40px;

  max-width: 1140px;
  margin: 0 auto;
  margin-top: 25px;
`;
