import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const Header = styled.div`
  gap: ${tokens.layout.size1};
  margin-bottom: ${tokens.layout.size5};
`;

export const NoDevicesSection = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${tokens.layout.size6};
  max-width: 1140px;
  gap: ${tokens.layout.size1};
`;

export const NoDevicesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const DevicesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  gap: ${tokens.layout.size5};
  flex: 1;

  @media screen and (max-device-width: ${tokens.layout.smMin}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const DevicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1140px;
  height: auto;
  gap: ${tokens.layout.size3};
  flex: 1;

  @media screen and (max-device-width: ${tokens.layout.smMin}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const RefreshSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
