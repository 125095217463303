import { gql } from '@clientos/graphql-client';

const GET_DEVICE = gql`
  query Query($deviceId: String!) {
    devices(deviceId: $deviceId) {
      items {
        deviceId
        identity {
          friendlyName
          serialNumber
          makeAndModel {
            name
            number
          }
        }
        images {
          url
        }
      }
      totalCount
    }
  }
`;

export default GET_DEVICE;
