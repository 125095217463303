import React from 'react';
import { tokens } from '@veneer/tokens';

export const Subtitle = ({
  children,
  ...rest
}: React.HTMLAttributes<HTMLParagraphElement> & {
  children: React.ReactNode;
}) => {
  return (
    <p
      {...rest}
      className={`subtitle-regular ${rest?.className || ''}`}
      style={{ fontSize: tokens.typography.size4, ...rest.style }}
    >
      {children}
    </p>
  );
};
