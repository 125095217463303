import styled from 'styled-components';

export const FormDeviceName = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const DeviceImage = styled.div`
  display: flex;

  & > svg,
  img {
    width: 135.18px;
    height: 103px;
  }
`;
