import { IconTools } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const StyledIconTools = styled(IconTools)`
  && {
    color: ${tokens.color.gray6};
    height: ${tokens.layout.size8};
    width: ${tokens.layout.size8};
  }
`;

export const ErrorContent = styled.div`
  display: flex;
  padding: 48px ${tokens.layout.size0};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${tokens.layout.size4};
  align-self: stretch;
  background-color: ${tokens.color.gray0};
`;

export const ErrorTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.layout.size2};
  align-self: stretch;
`;

export const ErrorTextContainer = styled.div`
  display: flex;
  padding: ${tokens.layout.size0} ${tokens.layout.size4};
  flex-direction: column;
  align-items: center;
  gap: ${tokens.layout.size1};
  align-self: stretch;
  > div {
    text-align: center;
  }
`;
