import { gql } from '@clientos/graphql-client';

const GET_SUBSCRIPTIONS = gql`
  query Query {
    account {
      subscriptions {
        resourceId
        friendlyId
        type
        state
        devices {
          items {
            deviceId
          }
        }
        entities {
          ... on InstantInkEntity {
            resourceId
            type
            product {
              description
            }
          }
          ... on DeviceEntity {
            resourceId
            type
            product {
              sku
              type
              name
              description
            }
          }
          ... on InstantPaperEntity {
            resourceId
            type
          }
          ... on CarePackEntity {
            resourceId
            type
          }
        }
      }
    }
  }
`;

export default GET_SUBSCRIPTIONS;
