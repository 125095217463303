import { useQuery } from '@clientos/graphql-client/dist/react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { IconArrowLeft } from '@veneer/core';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import DeviceDetails from 'src/components/DeviceDetails';
import MoreOptions from 'src/components/MoreOptions';
import Subscriptions from 'src/components/Subscriptions';
import UsersAndSharing from 'src/components/UsersAndSharing';
import GET_DEVICE from 'src/graphql/getDevice';
import { DeviceDetailsError, publishEvent } from 'src/utils/analytics';
import getDeviceType from 'src/utils/getDeviceType';
import { DeviceDetailsHeader } from './styles';
import { ContainerContent, Section } from 'src/styles/styles';

const DeviceDetailing = () => {
  const { translate, northboundAPIs } = useDependencyManagerContext();
  const { deviceId } = useParams();

  const { data, loading, error } = useQuery(GET_DEVICE, {
    variables: {
      deviceId
    }
  });

  const navigation = northboundAPIs.v1.navigation;
  const country = northboundAPIs.v1.localization.country;
  const language = northboundAPIs.v1.localization.language;

  const goBack = useCallback(
    () => navigation.push(`/${country}/${language}/devices`),
    [country, language, navigation]
  );

  useEffect(() => {
    if (error) {
      publishEvent(DeviceDetailsError(error?.message));
      goBack();
    }

    if (!loading && !error) {
      const deviceExists = data?.devices?.items[0]?.deviceId === deviceId;
      if (!deviceExists || !data?.devices?.totalCount) {
        goBack();
      }
    }
  }, [
    data?.devices?.items,
    data?.devices?.totalCount,
    deviceId,
    error,
    goBack,
    loading
  ]);

  const device = data?.devices?.items[0];

  const deviceDetailsAnalytics = `deviceType=${getDeviceType(
    device
  )}&productNumber=${device?.identity?.makeAndModel?.number}`;

  const showUsersAndSharing = getDeviceType(device) === 'printer';

  return (
    <>
      <DeviceDetailsHeader data-testid="DeviceDetailsHeader">
        <IconArrowLeft
          data-testid="IconArrowLeft"
          onClick={goBack}
        />
        <div className="subtitle-regular">
          {`${translate('devices.deviceDetails', 'Device details')}`}
        </div>
      </DeviceDetailsHeader>
      {loading ? (
        <Section
          appearance="outlined"
          content={<Loading />}
        />
      ) : (
        <ContainerContent>
          <DeviceDetails device={device} />
          <Subscriptions
            device={device}
            deviceDetails={deviceDetailsAnalytics}
          />
          {showUsersAndSharing && (
            <UsersAndSharing deviceDetails={deviceDetailsAnalytics} />
          )}
          <MoreOptions deviceDetails={deviceDetailsAnalytics} />
        </ContainerContent>
      )}
    </>
  );
};

export default DeviceDetailing;
