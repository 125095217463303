import React from 'react';
import { CustomImage, IconLaptop, IconPrinter } from '@veneer/core';
import { DeviceImage, DeviceType } from 'src/types/IDevices';

const IconMapping: Record<DeviceType, JSX.Element> = {
  PrinterDevice: (
    <IconPrinter
      data-testid="default-printer-image"
      disabled
    />
  ),
  PCDevice: (
    <IconLaptop
      data-testid="default-pc-image"
      disabled
    />
  )
};

const getDefaultIcon = (
  __typename: DeviceType = 'PrinterDevice'
): JSX.Element => IconMapping[__typename] || IconMapping.PrinterDevice;

const getDeviceImage = (images: DeviceImage[], __typename: DeviceType) => {
  if (!images?.length) {
    return getDefaultIcon(__typename);
  }

  const regex = /(\d+)(x|-)(\d+)/;
  let largestImage = { url: images[images.length - 1].url, area: 0 };

  images.forEach((image) => {
    const match = image.url.match(regex);
    if (match) {
      const width = parseInt(match[1], 10);
      const height = parseInt(match[3], 10); // match[3] because match[2] is the separator
      const area = width * height;
      if (area > largestImage.area) {
        largestImage = { url: image.url, area };
      }
    }
  });

  return (
    <CustomImage
      data-testid="device-image"
      src={largestImage.url}
      aria-description="device-image"
    />
  );
};

export default getDeviceImage;
