import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const DeviceDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: ${tokens.layout.size5};
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  color: ${tokens.color.gray10};
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;
